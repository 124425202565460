import moment from "moment";
import { truncate } from "lodash";

export const SUCCESS_NOTIFICATION = "success";
export const ERROR_NOTIFICATION = "danger";
export const WARNING_NOTIFICATION = "warning";

export const formatDate = (date, dateFormat = "D-MMM-YY") =>
  moment(date).format(dateFormat);

export const getStatusImage = (status, className) =>
  status ? (
    <img
      src="https://designs.dmb2024.com/assets/images/icons/green-dot.svg"
      width="17"
      alt="alive"
      {...(className && { className })}
    />
  ) : (
    <img
      src="https://designs.dmb2024.com/assets/images/icons/red-dot.svg"
      width="17"
      alt="deceased"
      {...(className && { className })}
    />
  );

export const compareDate = (date1, date2 = moment()) => {
  return moment(date1).isAfter(date2, "day");
};

export const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export const shortenText = (text, char = 20) => {
  return truncate(text, { length: char });
};

export const toSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  // str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-zA-Z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const sendSMS = async ({ token, data }) => {
  const response = await fetch(
    `https://notifications.dmb2024.com/Notifications/sms`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

export const regions = [
  "AHAFO",
  "ASHANTI",
  "BONO",
  "BONO EAST",
  "CENTRAL",
  "EASTERN",
  "GREATER ACCRA",
  "NORTHERN",
  "NORTH EAST",
  "OTI",
  "VOLTA",
  "SAVANNAH",
  "UPPER EAST",
  "UPPER WEST",
  "WESTERN",
  "WESTERN NORTH",
];

export const years = () => {
  let currentYear = new Date().getFullYear() + 4;
  const years = [];
  let startYear = 1901;
  while (currentYear >= startYear) {
    years.push(currentYear--);
  }
  return years;
};

export const countries = [
  "Ghana",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States of America (the)",
  "Germany",
  "South Africa",
  "Nigeria",
  "-",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United States Minor Outlying Islands (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const successMessage = (message) => ({
  show: true,
  type: SUCCESS_NOTIFICATION,
  message,
});

export const errorMessage = (message) => ({
  show: true,
  type: ERROR_NOTIFICATION,
  message,
});

export const warningMessage = (message) => ({
  show: true,
  type: WARNING_NOTIFICATION,
  message,
});

export const getPollingStations = async ({
  region = "",
  district = "",
  constituency = "",
  electoralArea = "",
  token,
}) => {
  const response = await fetch(
    `https://nec.dmb2024.com/Groups/search-fields?Field=pollingStation&Term=*&PageSize=1500${
      region && "&Region=" + region
    }${district && "&District=" + district}${
      electoralArea && "&ElectoralArea=" + electoralArea
    }${constituency && "&Constituency=" + constituency}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { results, status };
};

export const getElectoralAreas = async ({
  region,
  district,
  token,
  constituency,
}) => {
  const response = await fetch(
    `https://nec.dmb2024.com/Groups/search-fields?Field=electoralArea&Term=*&PageSize=1500${
      region && "&Region=" + region
    }${district && "&District=" + district}${
      constituency && "&Constituency=" + constituency
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { results, status };
};

export const getDistricts = async ({ region, token }) => {
  const response = await fetch(
    `https://nec.dmb2024.com/Groups/search-fields?Field=district&Term=*&PageSize=1500${
      region && "&Region=" + region
    }`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

export const getConstituencies = async ({ region, district, token }) => {
  const response = await fetch(
    `https://nec.dmb2024.com/Groups/search-fields?Region=${region}&PageSize=1500${
      district && "&District=" + district
    }&Field=constituency&Term=*`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { results, status };
};

export const contactFieldSearch = async ({
  region = "",
  district = "",
  constituency = "",
  token,
  field,
  term,
}) => {
  const response = await fetch(
    `https://nec.dmb2024.com/Contacts/search-fields?Field=${field}&Term=${term}${
      constituency && "&Constituency=" + constituency
    }${region && "&Region=" + region}
        ${district && "&District=" + district}&PageSize=1500`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

//Get all survey
//survey.dmb2024.com/survey?SurveyType=canvass
// https://survey.dmb2024.com/Survey?PageSize=1000&SortColumn=name
export const getAllSurvey = async ({ token, type = "" }) => {
  const response = await fetch(
    `https://survey-es.dmb2024.com/survey?PageSize=100`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();

  return { status, results };
};

//Get all groups
export const getAllGroups = async ({ token }) => {
  const response = await fetch(
    `https://nec.dmb2024.com/Groups?PageSize=10000&SortColumn=name`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

// const CANVASS_BASE_URL = "https://survey.dmb2024.com/backoffice/canvass";
const CANVASS_BASE_URL = "https://survey-es.dmb2024.com/canvasses";

//Create canvass
export const createCanvass = async ({ token, data }) => {
  const response = await fetch(
    "https://survey-es.dmb2024.com/backoffice/canvasses",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  const status = response.status;
  const results = await response.json();
  return { status, results };
};

export const getAllCanvass = async ({ token, pageIndex, pageSize }) => {
  const response = await fetch(`${CANVASS_BASE_URL}?Page=${pageIndex}&pageSize=${pageSize}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

const reArrangeString = (location) => {
  const arr = location?.split(" ");
  for (let i = 0; i < arr?.length; i++) {
    arr[i] = arr[i]?.charAt(0).toUpperCase() + arr[i]?.slice(1)?.toLowerCase();
  }
  const str2 = arr?.join(" ");
  return str2;
};

export const returnLocation = (country, region, district, constituency) => {
  if (region === "" && district === "" && constituency === "") {
    return country;
  } else if (region !== "" && district === "" && constituency === "") {
    const newRegion = reArrangeString(region);
    const location = `${country}; ${newRegion}`;
    return location;
  } else if (region !== "" && district !== "" && constituency === "") {
    const newRegion = reArrangeString(region);
    const newDistrict = reArrangeString(district);
    const location = `${country}; ${newRegion}: ${newDistrict}`;
    return location;
  } else {
    const newRegion = reArrangeString(region);
    const newDistrict = reArrangeString(district);
    const newconstituency = reArrangeString(constituency);
    const location = `${country}; ${newRegion}; ${newDistrict}; ${newconstituency}`;
    return location;
  }
};

export const getCanvass = async ({ token, id }) => {
  const response = await fetch(`${CANVASS_BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

export const updateCanvass = async ({ token, data, id }) => {
  const response = await fetch(
    `https://survey-es.dmb2024.com/backoffice/canvasses/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  const status = response.status;
  const results = await response.json();
  return { status, results };
};

export const deleteCanvass = async ({ token, id }) => {
  const response = await fetch(
    `https://survey-es.dmb2024.com/backoffice/canvasses/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;

  return { status };
};

export const getCanvassSurvey = async ({ token, id }) => {
  const response = await fetch(
    `${CANVASS_BASE_URL}/${id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
};

export const getCanvassRespondentsLocations = async ({ token, id }) => {
  const response = await fetch(
    `${CANVASS_BASE_URL}/${id}/responses/location`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
}

export const getCanvassSurveyResponses = async ({ token, id }) => {
  const response = await fetch(
    `${CANVASS_BASE_URL}/responses?SurveyId=${id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const status = response.status;
  const results = await response.json();
  return { status, results };
};


export const groupBy = (key, arr) => {
  return arr.reduce(
  (cache, product) => ({
    ...cache,
    [product[key]]:
      product[key] in cache ? cache[product[key]].concat(product) : [product],
  }),{});
}
  
export function normaliseResponse(responseArr) {
  const groupByContact = groupBy("canvassContactPhone", responseArr);
  let groupedByQuestionIndx = [];
  for (const property in groupByContact) {
    const singleContactGroup = groupByContact[property];
    const singleGroupByIndex = groupBy("questionId", singleContactGroup);
    groupedByQuestionIndx.push({ [property]: singleGroupByIndex });
  }
  return groupedByQuestionIndx;
}

export const getAllegianceColor = (value) => {
  const lowerCaseValue = value.toLowerCase();

  switch (lowerCaseValue) {
    case "strongly for us":
      return "green";
    case "somewhat for us":
      return "#90EE90";
    case "neutral":
      return "yellow";
    case "somewhat against us":
      return "orange";
    case "strongly against us":
      return "red";
    default:
      return "#E8E8E8";
  }
};

export const getAllegianceColorDNN = (value) => {
  if (value < -50 && value > -100) {
    return "black";
  } else if (value < 0 && value > -50) {
    return "grey";
  } else if (value > 0 && value < 51) {
    return "#2265D1";
  } else if (value > 49 && value < 101) {
    return "#19339F";
  } else {
    return "#E8E8E8";
  }
};

export const formatRouterQuery = (string) => {
  if(typeof string === "object" && string?.[0]){
    return string.join(" ")
  }
  if(typeof string === "string"){
    return string
  }
  return ""
}

export const capitalizeFirstLetters = (string) => {
  const strings = string?.split(" ");
  if(!strings) return string
  return strings.map((string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }).join(" ");
}
